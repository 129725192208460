const folderContext = require.context('../../../site_templates/', true, /\/images\/.*\.(png|jpe?g|gif|svg|webp)$/);

const importedImages = Array.from(folderContext.keys()).reduce((images, key) => {
  const folderName = key.split('/')[1];
  const folderImages = Array.from(folderContext.keys()).map((image) => ({
    folder: folderName,
    image: image.default,
  }));
  return [...images, ...folderImages];
}, []);

export default importedImages;
