require('@rails/ujs').start()

import 'jquery'
import 'bootstrap'

import 'common/src/components/footer'

import importedImages from 'common/src/components/imageImporter'

import 'common/src/site'

import './../images'
