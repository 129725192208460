// scroll body to 0px on click
$('.get-quotes-link').click(function () {
  showFormAndInitializeAutocomplete()
  scrollToJobForm()
});

$(function () {
  var urlParams = new URLSearchParams(window.location.search);
  if(urlParams.has('get_quotes')) {
    showFormAndInitializeAutocomplete()
    scrollToJobForm()
  }
});

function scrollToJobForm() {
  form = $('.site_form');

  const element = $('#job-form');

  element.removeClass('hidden');

  if (form.length > 0) {
    $('body,html').animate({
      scrollTop: form.offset().top
    }, 400);
  }

  element.addClass('animate__animated animate__fadeIn');
}
