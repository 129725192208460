$(function () {
  window.addEventListener('load', function() {
    var form = document.getElementById('jobForm');

    if(form) {
      form.addEventListener('submit', function(event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();

          checkRadioValidity(form.querySelector("input[name='job[starting]']"))
          checkRadioValidity(form.querySelector("input[name='job[preferred_outcome]']"))

          toggleSuburbInvalidFeedback();

          $('.js-clear_field').attr('disabled', 'disabled');
        }
        form.classList.add('was-validated');
      }, false);
    }
  }, false);
});

function checkRadioValidity(radioButton) {
  if (radioButton.checkValidity() === false) {
    radioButton.parentNode.parentNode.classList.add('group-invalid');
  } else {
    radioButton.parentNode.parentNode.classList.remove('group-invalid');
  }
}

function toggleSuburbInvalidFeedback() {
  $('#job_suburb_name:invalid').parent('.easy-autocomplete').siblings('.invalid-feedback').show()
  $('#job_suburb_name:valid').parent('.easy-autocomplete').siblings('.invalid-feedback').hide()

  $('#job_suburb_id:invalid').siblings('.invalid-feedback').show()
  $('#job_suburb_id:valid').siblings('.invalid-feedback').hide()
}
